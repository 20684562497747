import React, { useState } from "react";
import { Layout } from "../../../components/Layout";
import { Seo } from "../../../components/Seo";
import { PageHero, PageHeroWrap } from "../../../components/PageHero";
import { Container, Section, SectionTitle } from "../../../components/Section";
import { BenefitList, Benefit } from "../../../components/BenefitList";
import { PageTitle } from "../../../components/PageHeader";

import { Button } from "../../../components/Button";
import * as styles from "./MirrorColumnAutomationsApp.module.scss";

import { Link } from "../../../components/Link";
import { StaticImage } from "gatsby-plugin-image";
import { useRotateItems } from "../../../hooks/useRotateItems";
import ogImage from "./og_image.png";
import { getPrivacyUrl } from "../../../utils/urls";

// const storeUrl =
//   "https://auth.monday.com/oauth2/authorize?client_id=cf629e986046790a70a2d5ccb84e0bf1&response_type=install";
const storeUrl = "#";
const supportUrl =
  "https://forms.monday.com/forms/87a53de3919eb752956f42ffd4cab606";

const Page: React.FC = () => {
  return (
    <Layout noContact contactUrl={supportUrl}>
      <Seo
        ogFullTitle
        title="Mirror Column Automations monday.com App"
        description="Copy Mirror and Subitem summary columns to regular columns for use in automations and reporting."
        ogImage={ogImage}
        maxImagePreview="large"
      />
      <Container>
        <PageHero
          pageTitle={
            <PageTitle>
              <strong>Mirror Column Automations</strong> monday.com App
            </PageTitle>
          }
          pageCta={
            <div>
              <Link ext to={storeUrl}>
                <img
                  alt="Add to monday.com"
                  height="52"
                  src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                />
              </Link>
            </div>
            // <Link
            //   ext
            //   to={storeUrl}
            // >
            //   <img
            //     alt="Add to monday.com"
            //     height="42"
            //     src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
            //   />
            // </Link>
          }
          image={<PageHeroImage />}
        >
          <p>
            Mirror Column Automations overcomes all the limitations of Mirror
            columns and finally enables them everywhere, including automations and
            reporting.
          </p>
          <p>
            The app repeatedly copies values from Number, Timeline, People, and
            Status Mirror or Subitem summary columns into regular ones. It works
            both on the items and sub-item levels.
          </p>
        </PageHero>
      </Container>
      <Section>
        <SectionTitle>Features</SectionTitle>
        <BenefitList>
          <Benefit title="Copy Mirror columns to regular columns" />
          <Benefit title="Copy Subitem summary columns to regular columns" />
          <Benefit title="Copy subitem mirror columns to regular subitem columns" />
          <Benefit title="Supports Numbers, Timeline, People, and Status column types" />
          <Benefit title="Configurable update interval: 1 or 5 minutes" />
          <Benefit title="Copied columns are finally available in automations and reporting" />
        </BenefitList>
      </Section>
      <QuickStart />
      <Pricing />
      {/* <PrivacyDetails /> */}
      <UsageDetails />
    </Layout>
  );
};

const PageHeroImage: React.FC = () => {
  const images = [
    // <StaticImage
    //   src="./hero.png"
    //   alt="Create automations based on mirror columns"
    //   width={546}
    //   quality={95}
    //   placeholder="none"
    // />,
    <StaticImage
      src="./hero1.png"
      alt="Automatically copy mirror columns to regular ones"
      width={546}
      quality={95}
      placeholder="none"
      formats={["auto", "webp"]}
    />,
    <StaticImage
      src="./hero2.png"
      alt="Supports Numbers/Timeline/People/Status column types"
      width={546}
      quality={95}
      placeholder="none"
      formats={["auto", "webp"]}
    />,
    <StaticImage
      src="./hero3.png"
      alt="Supports Mirrored columns in subitems"
      width={546}
      quality={95}
      placeholder="none"
      formats={["auto", "webp"]}
    />,
  ];

  const current = useRotateItems({ items: images, interval: 5000 });
  return (
    <>
      {images.map((x, i) => (
        <div key={i} style={x === current ? undefined : { display: "none" }}>
          {x}
        </div>
      ))}
    </>
  );
};

const QuickStart: React.FC = () => (
  <Section id="quick-start">
    <SectionTitle>Quick Start</SectionTitle>
    <h3>Copying Item Level Columns</h3>
    <p>
      To copy Mirror/Subitem summary column to a regular column on the item
      level, use the provided integration recipe:
    </p>
    <blockquote>
      Check every <b>Time Value</b> if the <b>Mirror Column(title)</b> and{" "}
      <b>target column</b> are different then update target column.
    </blockquote>
    <ul className="list">
      <li>
        <strong>Time Value</strong> sets desired synchronization frequency:
        every 5 minutes or every 1 minute.
      </li>
      <li>
        <strong>Mirror Column(title)</strong> defines the source Mirror/Subitem
        summary column. Mirror columns can't be selected in automation recipes
        so just copy-paste the column title here. Please note that the column
        title is case-sensitive and must be unique when creating a recipe. Feel
        free to change the column title later; everything will still work.
      </li>
      <li>
        <strong>Target column</strong> allows selecting a regular target column
        where the app should copy values from the source column. Source and
        target columns must be of the supported types. After adding the recipe,
        it will copy values from the source column into the target column for
        all items on the board. Synchronization will continue running in the
        background, checking for differences between the columns every 1 or 5
        minutes and updating the item's target column when the source column
        value changes.
      </li>
    </ul>
    <p>
      After adding the recipe, it will copy values from the source column into
      the target column for all items on the board. Synchronization will
      continue running in the background, checking for differences between the
      columns every 1 or 5 minutes and updating the item's target column when
      the source column value changes.
    </p>

    <h3>Copying Subitem Columns</h3>
    <p>
      To copy a Mirror subitem column to a regular subitem column, use the
      integration recipe:
    </p>
    <blockquote>
      Check every <b>Time Value</b> if <b>Subitem Mirror Column(title)</b> and{" "}
      <b>Subitem target column</b> are different, update target column.
    </blockquote>
    <ul className="list">
      <li>
        <strong>Time Value</strong> sets desired synchronization frequency:
        every 5 minutes or every 1 minute.
      </li>
      <li>
        <strong>Subitem Mirror Column(title)</strong> defines the source subitem
        Mirror column.
      </li>
      <li>
        <strong>Subitem target column</strong> defines a target regular subitem
        column where the app should copy values from the source column. Source
        and target columns must be of the supported types.
      </li>
    </ul>
    <p>
      Please manually copy-paste the titles for source and target columns since
      subitem columns aren't available in recipes. Note that column titles are
      case-sensitive and must be unique when creating a recipe. Feel free to
      change the titles later, and everything will continue working.
    </p>
    <p>
      After adding the recipe, it will copy values from the source column into
      the target column for all subitems on the board. Synchronization will
      continue running in the background, checking for differences between the
      columns every 1 or 5 minutes and updating the subitem's target column when
      the source column value changes.
    </p>
  </Section>
);

const plans: {
  name: string;
  synchronizations: string;
  monthly: number;
  annual: number;
  desc: React.ReactNode;
}[] = [
    {
      name: "Free",
      synchronizations: "10 000",
      monthly: 0,
      annual: 0,
      desc: "Sync every 5 minutes: 1 integration across the entire account.",
    },
    {
      name: "Starter",
      synchronizations: "50 000",
      monthly: 59,
      annual: 49,
      desc: (
        <>
          <p>Sync every 5 minutes: 5 integrations across the entire account.</p>
          <p>or</p>
          <p>Sync every 1 minute: 1 integration across the entire account.</p>
        </>
      ),
    },
    {
      name: "Small",
      synchronizations: "100 000",
      monthly: 99,
      annual: 79,
      desc: (
        <>
          <p>Sync every 5 minutes: 10 integrations across the entire account.</p>
          <p>or</p>
          <p>Sync every 1 minute: 2 integrations across the entire account.</p>
        </>
      ),
    },
    {
      name: "Medium",
      synchronizations: "250 000",
      monthly: 199,
      annual: 159,
      desc: (
        <>
          <p>Sync every 5 minutes: 25 integrations across the entire account.</p>
          <p>or</p>
          <p>Sync every 1 minute: 5 integrations across the entire account.</p>
        </>
      ),
    },
    {
      name: "Large",
      synchronizations: "500 000",
      monthly: 349,
      annual: 279,
      desc: (
        <>
          <p>Sync every 5 minutes: 50 integrations across the entire account.</p>
          <p>or</p>
          <p>Sync every 1 minute: 10 integrations across the entire account.</p>
        </>
      ),
    },
    {
      name: "Unlimited",
      synchronizations: "Unlimited*",
      monthly: 999,
      annual: 799,
      desc: (
        <>
          <p>Unlimited integrations.</p>
          <p>
            Synchronizations are only limited by the monday.com account and
            monday.com API usage limits.
          </p>
          <p>Includes priority support.</p>
        </>
      ),
    },
  ];

const Pricing: React.FC = () => {
  const [monthlyBilling, setMonthlyBilling] = useState(false);

  return (
    <Section id="pricing">
      <SectionTitle>Pricing</SectionTitle>
      <div className={styles.billing}>
        <Button
          size="small"
          variant={monthlyBilling ? "cta" : "out"}
          onClick={() => setMonthlyBilling(true)}
        >
          Monthly
        </Button>
        <Button
          size="small"
          variant={!monthlyBilling ? "cta" : "out"}
          onClick={() => setMonthlyBilling(false)}
        >
          Yearly
        </Button>
      </div>
      <ul className={styles.pricing}>
        {plans.map(({ name, synchronizations, monthly, annual, desc }) => (
          <li key={name}>
            <h4>
              <Link to={storeUrl}>{name}</Link>
            </h4>

            <div className={styles.price}>
              {monthly === 0 ? (
                <>
                  <strong>$0</strong> / forever
                </>
              ) : monthlyBilling ? (
                <>
                  <strong>${monthly}</strong> / month
                </>
              ) : (
                <>
                  <s>${monthly}</s>
                  <strong>${annual}</strong> / month
                </>
              )}
            </div>

            <div className={styles.perks}>
              ✓ {synchronizations} synchronizations per month
              {/* {name === "Unlimited" && <div>✓ Priority support</div>} */}
            </div>

            <div className={styles.desc}>{desc}</div>
          </li>
        ))}
      </ul>
      <ul className={styles.misc}>
        <li>
          Synchronization means every time the board was checked for column
          difference, no matter how many items were updated. Recipes will make
          12 synchronizations per hour with 5 minutes update frequency and 60
          with a 1-minute frequency.
        </li>
        <li>
          *Synchronization count and frequency might be limited by the
          monday.com account and monday.com API usage limits.
        </li>
      </ul>
    </Section>
  );
};

const PrivacyDetails: React.FC = () => (
  <Section>
    <SectionTitle>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <StaticImage
          src="./gdpr128.png"
          alt="GDPR compliant"
          quality={95}
          width={48}
          height={48}
          formats={["auto", "webp"]}
        />
        <span>Data Privacy</span>
      </div>
    </SectionTitle>
    <BenefitList>
      <Benefit title="GDPR Compliant">
        LeanyLabs is an Estonian tech company that operates under GDPR.
      </Benefit>
      <Benefit title="Secure Platform">
        The app relies on SOC 2 Type II, ISO/IEC 27001:2013, PCI-DSS certified
        platform. It's hosted in Ireland.
      </Benefit>
      <Benefit title="User Profile">
        We store your email address to provide support.
      </Benefit>
      <Benefit title="Account Metadata">
        We store your monday.com account ID to link the purchase plan.
      </Benefit>
      <Benefit title="Recipes Configuration">
        We store monday.com board ID and column ID for your recipes.
      </Benefit>
      <Benefit title="Board Content">
        We read board content (only columns in the recipes) to update the target
        column. We <b>do not store</b> any data.
      </Benefit>
      <Benefit title="Credit Card Data">
        We <b>do not read</b> your credit card details. Purchases from monday's
        Apps Marketplace are handled by monday.com.
      </Benefit>
      <Benefit title="Learn More">
        Feel free to check LeanyLabs'{" "}
        <Link to={getPrivacyUrl()}>Privacy Policy</Link> for more information.
      </Benefit>
    </BenefitList>
  </Section>
);

const UsageDetails: React.FC = () => (
  <Section>
    <SectionTitle>Usage Details</SectionTitle>
    <h3>Copying Number Columns</h3>
    <p>
      Number Mirror column has additional settings in the "Column Settings" →
      "Show summary of" menu that define the aggregation:
    </p>
    <StaticImage
      src="./mirror_numbers.png"
      alt="Number mirror colunm settings"
      quality={95}
      formats={["auto", "webp"]}
    />
    <p>
      Mirror Column Automations will copy displayed values from a Mirror column
      to a regular Number column, accounting for the selected aggregation
      function.
    </p>

    <h3>Copying Timeline Columns</h3>
    <p>
      The Timeline Mirror column has additional settings in the "Column
      Settings" → "Show summary of" menu:
    </p>
    <StaticImage
      src="./mirror_timeline.png"
      alt="Timeline mirror colunm settings"
      quality={95}
      formats={["auto", "webp"]}
    />
    <p>
      If the source column is configured to display the "Earliest to Latest,"
      the recipe will copy both dates (earliest to latest) to the target
      Timeline column.
    </p>
    <p>
      In case one of the additional options: "Earliest" or "Latest" is selected,
      the value will only include a single date, and the app will copy this
      value to the target Timeline column.
    </p>

    <h3>Copying Status Columns</h3>
    <p>
      The Status Mirror column has additional settings in the "Column Settings"
      → "Show summary of" menu:
    </p>
    <StaticImage
      src="./mirror_status.png"
      alt="Status mirror colunm settings"
      quality={95}
      formats={["auto", "webp"]}
    />
    <p>
      If the source column is configured to display "All Labels," the recipe
      would copy all values to a target Dropdown column. It will only copy the
      status with the highest occurrence to a target regular Status column.
    </p>
    <p>
      When the "What's done" option is selected, Mirror Column Automations will
      only set the "Done" status to a target column when all items are done.
      Otherwise, the target Status column will have a default status.
    </p>

    <h3>Copying People Columns</h3>
    <p>
      If you have multiple users with identical names in your monday.com
      account, the app won't be able to copy those users to a target People
      column. Please <Link to={supportUrl}> let us know</Link> if that's the
      case in your organization.
    </p>

    <h3>Additional Notes</h3>
    <ul className="list">
      <li>
        Mirror Column Automations only supports Number, Timeline, People, and
        Status column types for Mirror, Subitem total, and Subitem columns.
      </li>
      <li>
        The source and target column types should match. One exception is the
        Status source column can have a regular Dropdown column target. You'll
        get an error message if column types or combinations aren't supported.
      </li>
      <li>
        When manually entering column titles and none or multiple columns match
        this title, adding a recipe will result in an error. Please note that
        column titles are case-sensitive. We suggest avoiding typos by
        copy-pasting the names just like they are on a board.
      </li>
      <li>
        If an item has no value in a source column, the app won't override the
        value in a target column.
      </li>
      <li>
        After the source column value changes, the app will update the target
        column value during the next synchronization that occurs as defined in
        the recipe. It won't happen in real-time.
      </li>
      <li>
        Copying a Mirror column that directly references another Mirror column
        won't work. As a workaround, you can use the app to copy a Mirror column
        to a regular column, then reference it in another Mirror column and
        finally copy it.
      </li>
      <li>
        Due to technical limitations, disabling the recipe won't stop background
        synchronizations. You have to delete the integration recipe from a
        board.
      </li>
    </ul>
    <p>
      If you have any questions feel free to{" "}
      <Link to={supportUrl}>contact our support team</Link>. We'll do our best
      to reply within two business days.
    </p>
  </Section>
);

export default Page;
